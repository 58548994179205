import Overlay from 'ol/Overlay';

import { v4 as uuidv4 } from 'uuid';

var jQuery = require("jquery");

export class InformationDialogBox {
    constructor(opt_options) {

        const defaults = {
            id: uuidv4(),
            map: null,
            overlay: null,
            recordGroups: {},
        };

        var options = Object.assign({}, defaults, opt_options);

        this.id = options.id
        this.map = options.map;
        this.overlay = options.overlay;
        this.recordGroups = options.recordGroups;
    }


    renderInfo(){
        let html = '<div class="info_dialog_box">';

        Object.keys(this.recordGroups).forEach(k=>{
            let g = this.recordGroups[k];
            let subHTML = '';
            subHTML += '<div class="info_dialog_box_group_title">' + g.title + '</div>';

            g.records.forEach(r=>{
                subHTML += '<div class="info_dialog_box_record">';
                for(let i=0; i<g.columns.length; i++){
                    if(!r.values[i]) return;
                    subHTML += '<div class="info_dialog_box_heading_value_pair">'
                    subHTML += '<span class="info_dialog_box_heading">';
                    subHTML += g.columns[i];
                    subHTML += '</span>'

                    subHTML += '<span class="info_dialog_box_value">';
                    subHTML += r.values[i];
                    subHTML += '</span>';

                    subHTML += '</div>';
                }
                subHTML += '</div>';
            });
            html += subHTML;
        });

        html += '</div>';

        return html;
    }

    show(coordinate, options){
        if(this.recordGroups.length < 1) return;

        const popupID = 'popup_' + this.id;
        const html = '<div id="' + popupID + '" class="ol-popup">\
        <a href="#" id="' + popupID + '-closer" class="ol-popup-closer"></a>\
        <div id="' + popupID + '-content"></div>\
    </div>';

        jQuery('#'+this.map.getTargetElement().id).parent().append(html);

        const popup = document.getElementById(popupID);

        if(!this.overlay){
            this.overlay = new Overlay({
                element: popup,
                autoPan: true,
                autoPanAnimation: {
                  duration: 250
                }
              });
        }

        this.map.getOverlays().push(this.overlay);

        try{
            let closer = document.getElementById(popupID + '-closer');
            closer.onclick = ()=>{
                this.overlay.setPosition(null);
                closer.blur();
                this.map.removeOverlay(this.overlay);
                return false;
            };
        }catch(e){};

        document.getElementById(popupID + '-content').innerHTML = this.renderInfo();
        this.overlay.setPosition(coordinate);
    }
}

export class RecordGroup{
    constructor(opt_options) {

        const defaults = {
            title: null,
            layerId: null,
            columns: [],
            records: new Set(),
        };

        var options = Object.assign({}, defaults, opt_options);

        this.columns = options.columns;
        this.groups = options.groups;
        this.records = options.records;
        this.title = options.title;
    }

    addRecord(record){
        this.records.add(record)
    }

    hasRecord(obj){
        return this.records.has(obj);
    }

    removeRecord(obj){
        this.records.remove(obj);
    }

    emptyRecords(){
        this.records.clear();
    }

}

export class Record {
    constructor(opt_options) {

        const defaults = {
            values: []
        };

        var options = Object.assign({}, defaults, opt_options);

        // this.columns = options.columns;
        this._values = options.values;
    }

    set values(values){
        this._values = values;
    }

    get values(){
        return this._values;
    }
}

export default InformationDialogBox;