import 'ol/ol.css';
import '../styles/map.css'
import '../styles/ol-layerswitcher-esk.css';
import 'sidebar-v2/css/ol3-sidebar.css';
import 'ol-contextmenu/dist/ol-contextmenu.css';
import '../styles/appStyles.sass';

import Emglogo from './emg_3d_glow.png';

import {Map, View} from 'ol';
import * as olProj from 'ol/proj';
import proj4 from 'proj4';

import {Layer, Group as LayerGroup, Image as ImageLayer, Tile as TileLayer} from 'ol/layer';
import {ImageWMS, XYZ, OSM, TileArcGISRest, BingMaps} from 'ol/source';

import Feature from 'ol/Feature';
import {default as Polygon ,fromExtent as PolygonfromExtent} from 'ol/geom/Polygon';
import WKT from 'ol/format/WKT';

import {defaults as defaultControls, Attribution, FullScreen, MousePosition} from 'ol/control';

import {createStringXY} from 'ol/coordinate';

import Overlay from 'ol/Overlay';

import {defaults as defaultInteractions, DragRotateAndZoom, Select} from 'ol/interaction';

import Sidebar from 'sidebar-v2/js/ol5-sidebar';
import LayerSwitcher from './ol-layerswitcher-esk';


import ContextMenu from 'ol-contextmenu'
import {easeIn, easeOut} from 'ol/easing';

import {default as InformationDialogBox, Record as IDBRecord, RecordGroup as IDBRecordGroup} from './InfoDialog';

proj4.defs("EPSG:28354","+proj=utm +zone=54 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
proj4.defs("EPSG:28355","+proj=utm +zone=55 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
proj4.defs("EPSG:28356","+proj=utm +zone=56 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");

import { v4 as uuidv4 } from 'uuid';

var jQuery = require("jquery");

var proj = olProj.get('EPSG:3857');

const bingMapsKey = 'AvQkXWjIzcNZ2My9WOuFwrRSE1NmvBg_BzmyhXoMBS4jSRoxVv6si2VU977rqQpg';

const bingStyles = [
  // 'RoadOnDemand',
  {imagerySet:'Aerial', displayName:'Bing Aerial'},
  {imagerySet:'AerialWithLabelsOnDemand', displayName:'Bing Aerial with Labels'},
  // 'CanvasDark',
  // 'OrdnanceSurvey'
];

// const wktConverter = new WKT();

function initMap(){
  setupMap();

  const Emglogo_object = new Image();
  Emglogo_object.src = Emglogo;
  const logodiv = document.getElementById("logo");
  logodiv.appendChild(Emglogo_object);
}



async function setupMap(){
  let layerSwitcher = new LayerSwitcher({reverse:true});

  let fullscreenControl = new FullScreen({
	  // source: document.getElementById('mapArea')
	});

  var ol_map = new Map({
    controls: defaultControls().extend([
      layerSwitcher,
      mousePositionControl,
      sidebar,
      contextmenu]),
    interactions: defaultInteractions().extend([
      new DragRotateAndZoom()
    ]),
    layers: [
      OverlayGroup,
      basemapGroup,
    ],
    target: 'map',
    view: new View({
      center:[15768630, -4469180],
      projection: proj,
      zoom: 8
    }),
    pixelRatio : window.devicePixelRatio
  });
  window.ol_map = ol_map;

  ol_map.on('singleclick', clickOnMap);

  // ol_map.on('pointermove', checkHover);
  //Not dynamic
  // updateWMSLegend(ol_map.getView().getResolution());

  rebuildLegend();

  // populateLayerGroup(base_map_defs, basemapGroup, rebuildLegend);
  // populateLayerGroup(overlay_defs, OverlayGroup, rebuildLegend);
}
window.initMap = initMap;



async function updateWMSLegend(resolution) {
  var graphicUrl = communityGTRSource.getLegendUrl(resolution);
  var img = document.getElementById('cmLegend');
  img.src = graphicUrl;
};

function rebuildLegend(){
  LayerSwitcher.renderPanel(ol_map,document.getElementById("layers"));
}

var hoverPixel;
var hoverTime;

async function checkHover(event){

  if(hoverPixel){
    let dTime = (performance.now() - hoverTime)
    if(dTime > 50 ){
      let dX = Math.abs(event.pixel[0] - hoverPixel[0]);
      let dY = Math.abs(event.pixel[0] - hoverPixel[0]);
      if(dX < 0.1 && dY < 0.1){
        clickOnMap(event)
        // return;
      }
      else{
        hoverPixel = event.pixel;
        hoverTime = performance.now();
      }
    }
    else{
      return;
    }
  }
  else{
    hoverPixel = event.pixel;
    hoverTime = performance.now();
  }

}

var infoOverlay;

function closeInfoOverlay(){
  infoOverlay.setPosition(null);
  // closer.blur();
  ol_map.removeOverlay(infoOverlay);
  return false;
}

async function clickOnMap(event){
  // const coordinate = event.coordinate;

  const viewResolution = /** @type {number} */ (ol_map.getView().getResolution());

  const url = communityGTRSource.getFeatureInfoUrl(
    event.coordinate, viewResolution, 'EPSG:3857',
    {'INFO_FORMAT': 'text/html'});//application/json
  if (url) {
    fetch(url)
      .then(function (response) { return response.text(); })
      .then(function (htmlData) {
        if(infoOverlay){
          closeInfoOverlay();
        } 

        if((typeof htmlData !== 'string') || (htmlData.search('class="record"') == -1)) return;

        const popupID = 'popup_' + uuidv4();
        const html = '<div id="' + popupID + '" class="ol-popup">\
            <a href="#" id="' + popupID + '-closer" class="ol-popup-closer"></a>\
            <div id="' + popupID + '-content"></div>\
        </div>';

        jQuery('#'+ol_map.getTargetElement().id).parent().append(html);

        const popup = document.getElementById(popupID);

        infoOverlay = new Overlay({
            element: popup,
            autoPan: true,
            autoPanAnimation: {
              duration: 250
            }
          });

        ol_map.getOverlays().push(infoOverlay);

        try{
            let closer = document.getElementById(popupID + '-closer');
            closer.onclick = closeInfoOverlay;
        }catch(e){};

        document.getElementById(popupID + '-content').innerHTML = htmlData;
        infoOverlay.setPosition(event.coordinate);
      });
  }
}


function getQueryPolygon(coordinates){
  const x = coordinates[0];
  const y = coordinates[1];

  //Need pixel size in map units
  const b = 2;

  const f =  PolygonfromExtent([x-b, y-b, x+b, y+b ]);

  const feature = new Feature({geometry: f});

  return wktConverter.writeFeatures([feature], {
    // dataProjection: 
    // featureProjection:
  });
}


function getVisibleLayers(layers){
	var vLayers = [];
	for(let i=0; i<layers.length; i++){
		let l = layers[i];

		if(l instanceof LayerGroup){
			vLayers = vLayers.concat(getVisibleLayers(l.getLayers().getArray()))
		}
		else{
			if(l.getVisible()){
				vLayers.push(l);
			}
		}
	}
	return vLayers;
}


// Tidy up / Move to another file?
// const olBaseMaps = {};
const baseMapLayers = [new TileLayer({
  title: 'Open Street Maps',
  type: 'base',
  source: new OSM(),
  visible: true,
  zIndex: 1
}),
  // new TileLayer({
  //   title: 'Geoscience Aus. Topo',
  //   type: 'base',
  //   source: new XYZ({
  //       url: 'http://gaservices.ga.gov.au/site_7/rest/services/Topographic_Base_Map_WM/MapServer/tile/{z}/{y}/{x}',
  //       projection : 'EPSG:3857',
  //       attributions: '© Commonwealth of Australia (Geoscience Australia) 2016. Creative Commons Attribution 4.0 International Licence.',
  //       crossOrigin : "anonymous",
  //       minZoom: 0,
  //       maxZoom: 12
  //   }),
  //   visible: false,
  //   zIndex: 1
  // }),
  // new ImageLayer({
  //   title: 'Geoscience Aus. DEM',
  //   type: 'base',
  //   //
  //   source: new ImageWMS({
  //     url: 'http://gaservices.ga.gov.au/site_9/services/DEM_SRTM_1Second_Hydro_Enforced/MapServer/WMSServer?',
  //     params: {
  //       'layers': 'Image',
  //       'FORMAT': 'image/jpeg'
  //     },
  //     projection: 'EPSG:3857',
  //     serverType: 'geoserver',
  //     crossOrigin: 'anonymous'
  //   }),
  //   visible: false,
  //   zIndex: 1
  // })
];


bingStyles.forEach(style=>{
  baseMapLayers.push(
    new TileLayer({
      title: style.displayName,
      type: 'base',
      visible: false,
      preload: Infinity,
      source: new BingMaps({
        key: bingMapsKey,
        imagerySet: style.imagerySet,
        maxZoom: 19
      }),
      zIndex: 1
    })
  );
});


const basemapGroup = new LayerGroup({
  title: 'Basemaps',
  layers: baseMapLayers,
  'fold': 'open'
});



const communityGTRSource = new ImageWMS({
  url: 'https://tfsfeed.eskmapping.com.au/geoserver/Esk_community/wms',
  params: {'LAYERS': 'Esk_community:gtr'},
  serverType: 'geoserver',
  crossOrigin: 'anonymous'
});

const communityGTRLayer = new ImageLayer({
  legendImageSrc:'https://tfsfeed.eskmapping.com.au/geoserver/Esk_community/wms?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetLegendGraphic&FORMAT=image%2Fpng&LAYER=Esk_community%3Agtr&SCALE=2183910.7260319907',
  legendLarge: true,
  title:'GTRPC',
  source: communityGTRSource,
  zIndex: 2
});

const OverlayGroup = new LayerGroup({
  title: 'Overlays',
  layers: [communityGTRLayer],
  'fold': 'open'
});


const mousePositionControl = new MousePosition({
  coordinateFormat: createStringXY(1),
  projection: 'EPSG:3857',
	coordinateFormat: formatMouseCoordinates,
  className: 'custom-mouse-position',
  target: document.getElementById('mouse-position'),
  undefinedHTML: ''
});

mousePositionControl.projEPSG = 'EPSG:4326';

const sidebar = new Sidebar({ element: 'sidebar_div', position: 'right' });


const locationMenuItem = {
  text: 'Coordinates',
  classname: 'some-style-class', // add some CSS rules
  callback: null // `center` is your callback function
};

const contextmenu_items = [
  locationMenuItem,
  '-',
  {
    text: 'Center map here',
    classname: 'some-style-class', // add some CSS rules
    callback: centerMap // `centerMap` is your callback function
  },
];

const contextmenu = new ContextMenu({
  width: 170,
  defaultItems: true, // defaultItems are (for now) Zoom In/Zoom Out
  items: [
    locationMenuItem,
  ]
});

function centerMap(obj) {
  ol_map.getView().animate({
    duration: 700,
    easing: easeOut,
    center: obj.coordinate,
  });
}

contextmenu.on('open', function(evt){
  const coordinate = ol_map.getCoordinateFromPixel(evt.pixel);

  locationMenuItem.text = formatMouseCoordinates(coordinate);


  contextmenu.clear();
  contextmenu.extend(contextmenu_items);
  contextmenu.extend(contextmenu.getDefaultItems());

  console.log(coordinate);
});




////////MOUSE POSITION
document.getElementById('projection').addEventListener('change', function(event) {
  mousePositionControl.projEPSG = event.target.value;
});


function formatMouseCoordinates(originalCoordinates){
  const targetEPSG = mousePositionControl.projEPSG;
  
  let sourceEPSG = "EPSG:3857";

  try{
    sourceEPSG = mousePositionControl.getMap().getView().getProjection().getCode();
  }
  catch(e){}

  const sProj = proj4.defs[sourceEPSG];
  const tProj = proj4.defs[targetEPSG];

	const projected = proj4(sProj, tProj, originalCoordinates);

	if(targetEPSG !="EPSG:4326"){
		return 'X:'+projected[0].toFixed(1) + ' Y:'+projected[1].toFixed(1); 
	}
	else{
		return 'Lat '+projected[1].toFixed(6) + ', Lon '+projected[0].toFixed(6);
	}
	
}